import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetInterviewCreditsURL = '/host-company/browse-intern/get-interview-credit';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const getInterviewCredits = createAsyncThunk(
  'getInterviewCredits',
  async (request, { rejectWithValue, fulfillWithValue, signal }) => {
    try {
      const result = await axios.get(GetInterviewCreditsURL, {
        signal,
      });
      if (result?.data?.status === 200) {
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const GetInterviewCreditsSlice = createSlice({
  name: 'getInterviewCredits',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getInterviewCredits.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(getInterviewCredits.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload.payload;
        state.isLoading = false;
      })
      .addCase(getInterviewCredits.rejected, (state, action) => {
        state.status = 'Failed';
        state.error = action.error.message || 'Something went wrong';
        state.isLoading = false;
      });
  },
});

export const getInterviewCreditsState = (state) => state.GetInterviewCredits;
export default GetInterviewCreditsSlice.reducer;
