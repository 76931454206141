import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const getLoggedInUserDetailsURL = '/host-company/user';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const getLoggedInUserDetails = createAsyncThunk(
  'getLoggedInUserDetails',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.get(getLoggedInUserDetailsURL);
      if (result?.data?.data?.isSuccess) {
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const GetLoggedInUserDetailsSlice = createSlice({
  name: 'getLoggedInUserDetails',
  initialState,
  reducers: {
    resetLoggedInUserDetails: () => ({ ...initialState }),
  },
  extraReducers(builder) {
    builder
      .addCase(getLoggedInUserDetails.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(getLoggedInUserDetails.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload.payload;
        state.isLoading = false;
      })
      .addCase(getLoggedInUserDetails.rejected, (state, action) => {
        state.status = 'Failed';
        state.isLoading = false;
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export const getLoggedInUserDetailsState = (state) => state.GetLoggedInUserDetails;
export const { resetLoggedInUserDetails } = GetLoggedInUserDetailsSlice.actions;
export default GetLoggedInUserDetailsSlice.reducer;
