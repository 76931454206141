import * as countryCodes from 'country-codes-list';
import moment from 'moment';
import ContentLoader from 'react-content-loader';
import { DateTime } from 'luxon';
import { configs } from './configs';
import { showSuccessMessage, showErrorMessage } from '../../Redux/Slices/Common/Notification';
import { getCountryByName } from 'Redux/Slices/Common/CountryPhoneCode';
export const getAllCountryPhoneCode = countryCodes.customList(
  'countryNameEn',
  '+{countryCallingCode}',
);

export const countryCodesSelectOptions = () => {
  const res = [];
  if (getAllCountryPhoneCode) {
    Object.keys(getAllCountryPhoneCode).forEach((key) => {
      res.push({ label: getAllCountryPhoneCode[key], value: key });
    });
  }
  return res;
};
export const makeAbbr = (str) => {
  // words is [ "central", "processing", "unit" ]
  let words = str.split(/\s+/);
  let abbreviation = '';
  for (let i = 0; i < words.length; i++) {
    abbreviation += words[i][0];
  }
  return abbreviation.toUpperCase();
};
export const getCountryByCode = (code) => {
  const countryObj = countryCodes.findOne('countryCallingCode', code.split('+')[1]);
  return { label: code, value: countryObj?.countryNameEn };
};
export const getAbbreviation = (timezoneName, timeZoneList) => {
  return timeZoneList?.find((item) => item?.value === timezoneName)?.entity?.abbreviation || '';
};

export const validateVideoUrl = (url) => {
  // Regular expressions to match YouTube and Vimeo URLs
  const youtubeRegExp =
    //  eslint-disable-next-line
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/m;

  const vimeoRegExp =
    //  eslint-disable-next-line
    /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|)(\d+)(?:$|\/|\?)/;

  // Test the URL against the regular expressions
  const youtubeMatch = url.match(youtubeRegExp);
  const vimeoMatch = url.match(vimeoRegExp);

  // Return true if the URL matches either regular expression, false otherwise
  return youtubeMatch !== null || vimeoMatch !== null;
};

const Utils = {
  isFunction(value) {
    return typeof value === 'function';
  },

  isUndefined(value) {
    return typeof value === 'undefined';
  },

  isNull(value) {
    return value === 'null';
  },
  isEmpty(value) {
    return value === '';
  },
  isBoolean(value) {
    return typeof value === 'boolean';
  },

  isNumber(value) {
    return typeof value === 'number';
  },

  isEmptyList(value) {
    return value.constructor !== Array || value.length === 0;
  },

  isEmptyObject(value) {
    if (value === null) {
      return true;
    }

    if (Utils.isBoolean(value) || Utils.isNumber(value) || Utils.isFunction(value)) {
      return false;
    }

    if (value instanceof Set && !Utils.isUndefined(value.size) && value.size !== 0) {
      return false;
    }

    return Object.keys(value).length === 0;
  },

  isStringAndNotEmpty(value) {
    return typeof value === 'string' && value !== '';
  },

  isString(value) {
    return typeof value === 'string';
  },

  isUndefinedOrNull(value) {
    return Utils.isUndefined(value) || Utils.isNull(value);
  },

  isUndefinedOrNullOrEmpty(value) {
    if (value === null) {
      return true;
    }
    return Utils.isUndefinedOrNull(value) || Utils.isEmpty(value);
  },

  isUndefinedOrNullOrEmptyObject(value) {
    return Utils.isUndefinedOrNullOrEmpty(value) || Utils.isEmptyObject(value);
  },

  isUndefinedOrNullOrEmptyList(value) {
    return Utils.isUndefinedOrNull(value) || Utils.isEmptyList(value);
  },

  isUndefinedOrNullOrEmptyOrEmptyObjectOrEmptyList(value) {
    return (
      Utils.isUndefinedOrNullOrEmpty(value) ||
      Utils.isEmptyObject(value) ||
      Utils.isUndefinedOrNullOrEmptyList(value)
    );
  },

  getNextMonthsandYear(count) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const today = new Date();
    let d;
    let month;
    let year;
    const result = [];
    for (let i = 0; i < count; i += 1) {
      d = new Date(today.getFullYear(), today.getMonth() + i, 1);
      month = monthNames[d.getMonth()];
      year = d.getFullYear();
      const val = `${month?.slice(0, 3)} ${year}`;
      result.push({ label: val, value: val });
    }
    return result;
  },
};

export default Utils;

export const checkParamValue = (param) => {
  if (param !== undefined && param !== null && param !== '') {
    return true;
  }
  return false;
};

export const checkArrayIsValid = (arr) => {
  if (Array.isArray(arr) && arr.length > 0) {
    return true;
  }
  return false;
};
export const isEmptyObject = (obj) =>
  Object.keys(obj).every((prop) => Object.prototype.hasOwnProperty.call(obj, prop));

export const countKeysInObject = (obj) => {
  let count = 0;
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) count++;
  }
  return count;
};

export const showSuccessMessageUtil = ({ text, dispatch, title }) => {
  dispatch(
    showSuccessMessage({
      text,
      title,
    }),
  );
};

export const showErrorMessageUtil = ({ text, title, dispatch }) => {
  dispatch(
    showErrorMessage({
      text,
      title,
    }),
  );
};

export const seconds2time = (secondsDataVal) => {
  if (isNaN(secondsDataVal)) {
    return '00:00';
  }

  const secondsData = Math.round(secondsDataVal);
  const hours = Math.floor(secondsData / 3600);
  const minutes = Math.floor((secondsData % 3600) / 60);
  const seconds = secondsData % 60;

  const hoursStr = hours > 0 ? `${hours}:` : '';
  const minutesStr = hours > 0 && minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;

  const time = `${hoursStr}${minutesStr}:${secondsStr}`;

  return time;
};

export const allValuesNullOrUndefinedOrEmpty = (obj) => {
  const keys = Object.keys(obj);
  return keys.every((key) => {
    const value = obj[key];
    return !value && value !== 0;
  });
};
export const getScreenWidth = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth < 992) {
    return 'medium';
  } else if (screenWidth < 534) {
    return 'small';
  } else {
    return 'large';
  }
};

export const timeAgo = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return `${interval} year${interval > 1 ? 's' : ''} ago`;
  }

  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return `${interval} month${interval > 1 ? 's' : ''} ago`;
  }

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return `${interval} day${interval > 1 ? 's' : ''} ago`;
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return `${interval} hour${interval > 1 ? 's' : ''} ago`;
  }

  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return `${interval} minute${interval > 1 ? 's' : ''} ago`;
  }

  if (seconds < 10) return 'just now';

  return `${Math.floor(seconds)} seconds ago`;
};

export const isValidUrl = (str) => {
  const pattern = new RegExp(
    '^([a-zA-Z]+:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i',
  );
  return pattern.test(str);
};

export const getTimezoneOffset = (zoneName) => {
  const now = DateTime.now().setZone(zoneName);
  const offsetInMinutes = now.offset;
  return offsetInMinutes;
};

export function componentLoader(lazyComponent, attemptsLeft = 1) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 0) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1500);
      });
  });
}

export function navigateTo(navigate, path, options) {
  if (path === -1) {
    navigate(path, options);
  } else if (typeof path === 'object') {
    const pathFromObject = path?.pathname;
    const query = path?.search;

    let searchQuery = sessionStorage.getItem('searchQuery');
    // updating serach query with '&'
    let updatedSearchQuery = searchQuery ? searchQuery.replace(/\?/g, () => '&') : '';

    // if same search query is already in the url the
    // make the search query empty
    if (pathFromObject?.includes(searchQuery) || pathFromObject?.includes(updatedSearchQuery)) {
      updatedSearchQuery = null;
    }

    // making sure only there should be only one '?' in path
    const queryString = updatedSearchQuery ? `${query}${updatedSearchQuery}` : `${query}`;
    const updatedQueryString = queryString.replace(/\?/g, (match, index) =>
      index === 0 ? '?' : '&',
    );

    const updatePath = updatedSearchQuery
      ? `${pathFromObject}${updatedQueryString}`
      : `${pathFromObject}${updatedQueryString}`;

    navigate(updatePath, options);
  } else if (path) {
    let searchQuery = sessionStorage.getItem('searchQuery');
    let updatedSearchQuery = searchQuery ? searchQuery.replace(/\?/g, () => '&') : '';
    // if same search query is already in the url the
    // make the search query empty
    if (path?.includes(searchQuery) || path?.includes(updatedSearchQuery)) {
      searchQuery = null;
    }
    const updatePath = searchQuery ? `${path}${searchQuery}` : path;
    navigate(updatePath, options);
  }
}
export const reverseMMYYYY = (date) => {
  if (Utils.isUndefinedOrNull(date) || date === null) {
    return null;
  }
  if (date === 'Invalid date') {
    return null;
  }
  // Convert 08/2023 to 2023/08/14
  const newString = date?.split('/')?.[1] + '/' + date?.split('/')?.[0] + '/14';

  return moment(new Date(newString));
};
export const disableWindowBack = () => {
  window.history.pushState(null, null, location.href);
  window.onpopstate = function () {
    history.go(1);
  };
};
export const formatDateToAbbreviatedMonth = (inputDate) => {
  if (inputDate === null) {
    return '';
  }
  const [month, year] = inputDate.split('/');

  // Convert the numeric month to its three-letter abbreviation
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const formattedMonth = monthNames[Number(month) - 1];

  // Combine the formatted month and year
  const formattedDate = `${formattedMonth} ${year}`;

  return formattedDate;
};

export function containsCalendlyLink(input) {
  // Regular expression to match Calendly meeting links
  const calendlyRegex = /https:\/\/calendly\.com\/[\w-]+\/[\w-]+/g;

  // Test if the input contains any matches for the Calendly link regex
  return !calendlyRegex.test(input);
}

export function ensureHttpsUrl(url) {
  const hasProtocol = url?.startsWith('http://') || url?.startsWith('https://');
  if (!hasProtocol) {
    if (url?.startsWith('www.')) {
      return 'https://' + url;
    }
    return 'https://www.' + url;
  }
  return url;
}

export function formatCustomDate(dateString, timezone) {
  const formattedDate = convertToTimeZoneAndFormat(
    dateString,
    timezone || 'GMT',
    "dd MMM yyyy 'at'  h:mm a", // eslint-disable-line
  );
  const timeZoneAbbreviation = convertToTimeZoneAndFormat(dateString, timezone || 'GMT', 'ZZZZ');
  const finalFormattedDate = `${formattedDate} (${timeZoneAbbreviation})`;
  return finalFormattedDate;
}

export function extractAttributes(obj, attributesToExtract) {
  const extractedValues = {};

  function recursiveExtract(obj) {
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        if (attributesToExtract.includes(key)) {
          extractedValues[key] = obj[key];
        } else if (typeof obj[key] === 'object') {
          recursiveExtract(obj[key]);
        }
      }
    }
  }

  recursiveExtract(obj);
  return extractedValues;
}
export function extractKeysWithNonObjectValues(obj, maxKeys = 10) {
  const result = {};
  let keysCount = 0;

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key) && keysCount < maxKeys) {
      if (typeof obj[key] !== 'object') {
        result[key] = obj[key];
        keysCount++;
      }
    }
  }

  return result;
}

const loaderStyles = {
  height: '100dvh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '32px',
};

export function contentDefaultLoader() {
  return (
    <div style={loaderStyles}>
      <ContentLoader viewBox='0 0 980 390'>
        <rect x='0' y='0' rx='5' ry='5' width='1570' height='30' />
        <rect x='0' y='50' rx='5' ry='5' width='570' height='30' />

        <rect x='0' y='110' rx='5' ry='5' width='1570' height='220' />
      </ContentLoader>
    </div>
  );
}

export const getUrlParams = (urlParameter) => {
  let searchOptions = { where: {} };
  let sortOptions = { sort: {} };
  let paginationOptions = { pagination: {} };
  let filterOptions = { filter: {} };
  let type = '';

  urlParameter &&
    Object.keys(urlParameter)?.forEach((key) => {
      if (key.includes('filter')) {
        let newKey = key.replace('filter_', '');
        filterOptions.filter[newKey] = urlParameter[key];
      } else if (key.includes('search')) {
        let newKey = key.replace('search_', '');
        searchOptions.where[newKey] = { _like: urlParameter[key] };
      } else if (key.includes('sort')) {
        let newKey = key.replace('sort_', '');
        sortOptions.sort[newKey] = urlParameter[key];
      } else if (key.includes('pagination')) {
        let newKey = key.replace('pagination_', '');
        paginationOptions.pagination[newKey] = parseInt(urlParameter[key]);
      } else if (key.includes('type')) {
        type = urlParameter[key];
      }
    });

  return {
    searchOptions,
    sortOptions,
    paginationOptions,
    filterOptions,
    type,
  };
};

export const removeParams = (urlParameter, paramToRemove = []) => {
  let params = {};
  urlParameter &&
    Object.keys(urlParameter).forEach((key) => {
      if (paramToRemove.some((param) => key.includes(param))) {
        params[key] = undefined;
      }
    });

  return params;
};

export function updateURLWithSearchParam(paramName, paramValue) {
  // Get the current pathname and search parameters
  const currentPath = window.location.pathname;
  const currentSearchParams = new URLSearchParams(window.location.search);

  // Set the parameter to the desired value
  currentSearchParams.set(paramName, paramValue);

  // Create a new URL with the updated search parameters
  const newUrl = `${currentPath}?${currentSearchParams.toString()}`;

  // Use window.location.assign to update the URL
  window.location.assign(newUrl);
}

export function removeURLSearchParam(paramName, navigate, location) {
  // Get the current search parameters
  const searchParams = new URLSearchParams(location.search);

  // Remove the parameter
  searchParams.delete(paramName);

  // Create a new search string
  const searchString = searchParams.toString();

  // Navigate to the updated URL
  navigate({
    pathname: location.pathname,
    search: searchString ? `?${searchString}` : '',
  });
}
export function convertToTimeZoneAndFormat(
  date,
  timezone = 'UTC',
  dateFormat = '',
  isBefore = false,
  uniqueIdentifier,
) {
  if (!date) {
    return null;
  }
  try {
    let luxonDate;
    if (uniqueIdentifier) {
      console.log(uniqueIdentifier, date, timezone, isBefore);
    }

    // Detect the input format and create a Luxon DateTime object accordingly
    if (date instanceof Date) {
      // JavaScript Date object
      luxonDate = DateTime.fromJSDate(date);
    } else if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}/)) {
      // ISO 8601 date-time string
      luxonDate = DateTime.fromISO(date);
    } else if (typeof date === 'number' && date.toString().length === 10) {
      // Unix timestamp in seconds
      luxonDate = DateTime.fromMillis(date * 1000);
    } else if (typeof date === 'number' && date.toString().length === 13) {
      // Unix timestamp in milliseconds
      luxonDate = DateTime.fromMillis(date);
    } else {
      throw new Error('Invalid date format');
    }

    if (timezone !== 'UTC') {
      // Set the timezone if it's not UTC
      luxonDate = luxonDate.setZone(timezone);
    }

    if (isBefore) {
      // If isBefore is true, perform a date comparison
      const now = DateTime.now().setZone(timezone);
      return luxonDate < now;
    } else {
      // If isBefore is false or not provided, conditionally format the DateTime object

      return dateFormat ? luxonDate.toFormat(dateFormat) : luxonDate;
    }
  } catch (error) {
    // Handle any errors, e.g., invalid date format
    console.error('Error:', error.message);
    return null; // Return null or an error message as needed
  }
}

export const objectToQueryString = (obj) =>
  Object.entries(obj)
    .filter(([key, value]) => {
      if (Array.isArray(value) && key) {
        return value.length > 0;
      }
      return value !== undefined && value !== null;
    })
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${encodeURIComponent(key)}[]=${value
          .map((item) => encodeURIComponent(item))
          .join(',')}`;
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');

export const getCdnUrl = (url) => {
  return configs.REACT_APP_ASSET_BASE_URL + url;
};

export const scrollToTop = (componentId) => {
  const component = document.getElementById(componentId);
  if (component) {
    component.scrollTop = 0;
  }
};
export const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name, sx = {}) {
  const nameParts = name.split(' ');
  const initials =
    nameParts.length > 1 && nameParts[1].length > 1
      ? `${nameParts[0][0]}${nameParts[1][0]}`
      : `${nameParts[0][0]}`;

  return {
    sx: {
      ...sx,
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
}
export function formatUnixTimestamp(timestamp) {
  try {
    // Ensure the input is a valid timestamp (Unix epoch is January 1, 1970)
    if (isNaN(timestamp) || timestamp < 0 || timestamp > 2147483647) {
      throw new Error('Invalid timestamp');
    }

    // Convert the timestamp to a Luxon DateTime object
    const dateTime = DateTime.fromSeconds(timestamp);

    // Get the day of the month
    const day = dateTime.day;

    // Add the appropriate suffix to the day
    let dayWithSuffix;
    if (day === 1 || day === 21 || day === 31) {
      dayWithSuffix = `${day}st`;
    } else if (day === 2 || day === 22) {
      dayWithSuffix = `${day}nd`;
    } else if (day === 3 || day === 23) {
      dayWithSuffix = `${day}rd`;
    } else {
      dayWithSuffix = `${day}th`;
    }

    // Format the DateTime object as "dS MMMM, yyyy"
    const formattedDate = dateTime.toFormat(`'${dayWithSuffix}' MMMM, yyyy`);

    return formattedDate;
  } catch (error) {
    return `Error: ${error.message}`;
  }
}

export function reverseFormatUnixTimestamp(timestamp, weeksToAdd) {
  try {
    // Ensure the input is a valid timestamp (Unix epoch is January 1, 1970)
    if (isNaN(timestamp) || timestamp < 0 || timestamp > 2147483647) {
      throw new Error('Invalid timestamp');
    }

    // Ensure the weeksToAdd is a valid number
    if (isNaN(weeksToAdd)) {
      throw new Error('Invalid number of weeks');
    }

    // Convert the timestamp to a Luxon DateTime object
    const dateTime = DateTime.fromSeconds(timestamp);

    // Add the specified number of weeks
    const newDateTime = dateTime.plus({ weeks: weeksToAdd });

    // Get the Unix timestamp of the new DateTime object
    const newTimestamp = Math.floor(newDateTime.toSeconds());

    return newTimestamp;
  } catch (error) {
    return NaN;
  }
}

export function addWeeksAndFormat(timestamp, weeksToAdd) {
  try {
    // Ensure the input is a valid timestamp (Unix epoch is January 1, 1970)
    if (isNaN(timestamp) || timestamp < 0 || timestamp > 2147483647) {
      throw new Error('Invalid timestamp');
    }

    // Ensure the weeksToAdd is a valid number
    if (isNaN(weeksToAdd)) {
      throw new Error('Invalid number of weeks');
    }

    // Convert the timestamp to a Luxon DateTime object
    const dateTime = DateTime.fromSeconds(timestamp);

    // Add the specified number of weeks
    const newDateTime = dateTime.plus({ weeks: weeksToAdd });

    // Get the day of the month
    const day = newDateTime.day;

    // Add the appropriate suffix to the day
    let dayWithSuffix;
    if (day === 1 || day === 21 || day === 31) {
      dayWithSuffix = `${day}st`;
    } else if (day === 2 || day === 22) {
      dayWithSuffix = `${day}nd`;
    } else if (day === 3 || day === 23) {
      dayWithSuffix = `${day}rd`;
    } else {
      dayWithSuffix = `${day}th`;
    }

    // Format the new DateTime object as "dS MMMM, yyyy"
    const formattedDate = newDateTime.toFormat(`'${dayWithSuffix}' MMMM, yyyy`);

    return formattedDate;
  } catch (error) {
    return '';
  }
}
export function isTodayInArray(jsonArray) {
  try {
    let datesArray;
    try {
      datesArray = JSON.parse(jsonArray);
    } catch {
      return false;
    }

    const today = new Date()?.toISOString().slice(0, 10);

    if (Array.isArray(datesArray)) {
      return datesArray?.includes(today);
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error parsing or processing the array:', error);
    return false;
  }
}
export function getNextDateNotInArray(jsonArray) {
  try {
    const datesArray = JSON.parse(jsonArray);
    const today = DateTime.local().toISODate();

    if (Array.isArray(datesArray)) {
      let nextDate = DateTime.fromISO(today).plus({ days: 1 });

      while (datesArray.includes(nextDate.toISODate())) {
        nextDate = nextDate.plus({ days: 1 });
      }

      return nextDate.toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' });
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error parsing or processing the array:', error);
    return null;
  }
}

export const calculateOfferEndDate = (startDate, duration) => {
  const endDate = Math.floor(startDate) + duration * 604800;
  const endDateObject = new Date(endDate * 1000);
  if (endDateObject.getDay() === 1) {
    endDateObject.setDate(endDateObject.getDate() - 3);
  }
  const modifiedDate = Math.floor(endDateObject.getTime() / 1000);
  const dateTime = DateTime.fromSeconds(modifiedDate);
  const day = dateTime?.day;
  let dayWithSuffix;
  if (day === 1 || day === 21 || day === 31) {
    dayWithSuffix = `${day}st`;
  } else if (day === 2 || day === 22) {
    dayWithSuffix = `${day}nd`;
  } else if (day === 3 || day === 23) {
    dayWithSuffix = `${day}rd`;
  } else {
    dayWithSuffix = `${day}th`;
  }

  const formattedDate = dateTime?.toFormat(`'${dayWithSuffix}' MMMM, yyyy`);
  return formattedDate;
};

export const convertTime24to12 = (time24) => {
  const [hours, minutes] = time24.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const hours12 = hours % 12 || 12;
  const time12 = `${String(hours12).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0',
  )} ${period}`;

  return time12;
};

export const getLeftCharactersTextColor = (charactersLeft) => {
  return charactersLeft >= 100 ? 'color-69707D' : 'color-FAAF00';
};

export const htmlToPlainTextLength = (html) => {
  const textWithoutTags = html ? html.replace(/<[^>]*>/g, '').replace(/\n/g, '') : '';
  const textLength = textWithoutTags.length;
  return textLength;
};

export const getFileTypeFromURL = (url) => {
  const segments = url?.split('.');
  const extension = segments?.pop();
  return extension?.toLowerCase(); // Convert to lowercase for consistency
};

export const isMobile = {
  Android() {
    return navigator.userAgent.match(/Android/i);
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Windows() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any() {
    return isMobile.Android() || isMobile.iOS() || isMobile.Windows();
  },
};

export function getUtmParams(url) {
  const urlParams = new URL(url).searchParams;
  const utmParams = {};

  for (const [key, value] of urlParams) {
    if (key.toLowerCase().startsWith('utm_')) {
      utmParams[key] = value;
    }
  }

  return utmParams;
}

export function matchIsNumeric(text) {
  const isNumber = typeof text === 'number';
  const isString = typeof text === 'string';
  return (isNumber || (isString && text !== '')) && !isNaN(Number(text));
}

export const getFormattedCountryCodes = () => {
  const countryCode = countryCodesSelectOptions();
  const countryCodeList = [];
  for (
    let countryCodeIterator = 0;
    countryCodeIterator < countryCode?.length;
    countryCodeIterator++
  ) {
    const countryDetails = getCountryByName(countryCode[countryCodeIterator]?.value);
    countryCodeList.push({
      label: `${countryDetails?.flag} ${countryCode[countryCodeIterator]?.label}`,
      country: countryCode[countryCodeIterator]?.value,
      value: countryCode[countryCodeIterator]?.label,
    });
  }
  return countryCodeList;
};

export const filterCountryCodeOptions = (options, { inputValue }) =>
  options.filter(
    (option) =>
      option.label?.toLowerCase().includes(inputValue?.toLowerCase()) ||
      option.country?.toLowerCase().includes(inputValue?.toLowerCase()),
  );

export const renderCountryCodeOption = (props, option) => (
  <li {...props} key={option.value + option.label + option.country}>
    <div>
      {option.label}
      <br />
      <span className='fs-12 color-656565'>{option.country}</span>
    </div>
  </li>
);
