import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';
import { getLoggedInUserDetails } from '../Rbac/GetLoggedInUserDetails';
import { getAdminDetails } from './GetAdminDetails';

const saveMissingInfoOfHCURL = '/host-company/poc';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const saveMissingInfoOfHC = createAsyncThunk(
  'saveMissingInfoOfHC',
  async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const result = await axios.patch(saveMissingInfoOfHCURL, request);
      if (result?.data?.data?.isSuccess) {
        dispatch(getLoggedInUserDetails());
        dispatch(getAdminDetails());
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const saveMissingInfoOfHCSlice = createSlice({
  name: 'saveMissingInfoOfHC',
  initialState,
  reducers: {
    resetSaveMissingInfoOfHC: () => ({ ...initialState }),
  },
  extraReducers(builder) {
    builder
      .addCase(saveMissingInfoOfHC.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(saveMissingInfoOfHC.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload.payload;
        state.isLoading = false;
      })
      .addCase(saveMissingInfoOfHC.rejected, (state, action) => {
        state.status = 'Failed';
        state.isLoading = false;
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export const saveMissingInfoOfHCState = (state) => state.saveMissingInfoOfHC;
export const { resetSaveMissingInfoOfHC } = saveMissingInfoOfHCSlice.actions;
export default saveMissingInfoOfHCSlice.reducer;
