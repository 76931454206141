import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { configs } from '../../Utils/Common/configs';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { GetPersonalInformationApi } from '../Slices/InternExperience/Application/GetPersonalInformation';
import { GetSkillsAndGoalsApi } from '../Slices/InternExperience/Application/GetSkillsAndGoals';
import { GetEducationApi } from '../Slices/InternExperience/Application/GetEducation';
import { GetUniversityApi } from '../Slices/InternExperience/Application/GetUniversity';
import { setUnsubscribe } from '../Slices/InternExperience/Application/SetApplicationNextLoader';
import { GetPastExpApi } from '../Slices/InternExperience/Application/GetPastExp';
import { GetAdditionalQuestionApi } from '../Slices/InternExperience/Application/GetAdditionalQuestion';
import { GetProfilePictureApi } from '../Slices/InternExperience/Profile/GetInternProfilePicture';
import { GetApplicationShortApi } from '../Slices/InternExperience/Application/GetApplicationShort';
import { GetPortfolioApi } from '../Slices/InternExperience/Application/GetPortfolio';
import { GetInternListApi } from '../Slices/Company/ViTalentPool/GetInternListNew';
import { ShortListInternRemoveApi } from '../Slices/Company/ViTalentPool/ShortListInternRemove';
import { ShortListInternSaveApi } from '../Slices/Company/ViTalentPool/ShortListInternSave';
import { GetShortListInternListApi } from '../Slices/Company/ViTalentPool/GetShortListInternNew';
import { GetConfirmInterviewDetailApi } from '../Slices/Company/ViTalentPool/GetConfirmInterviewDetail';
import { InternshipOpportunityApi } from '../Slices/Company/InternshipOpportunity/InternshipOpportunity';
import { GetInternAvailableApi } from '../Slices/Company/ViTalentPool/GetInternAvailablebyUuid';
import { GetCalendarListApi } from '../Slices/Auth/CalendarList';
import { LoadLocalesApi } from '../Slices/Common/LoadLocalesApi';
import { CandidatureApi } from '../Slices/Company/Candidature/Candidature';
import { CreditsApi } from '../Slices/Company/Admin/GetHcCredits';
import { GetInterviewTimeApi } from '../Slices/Company/Interview/GetInterviewTime';
import { GetInternInterviewTimeApi } from '../../Intern/Pages/Interview/GetInterviewTime';
import { DashboardApi } from '../Slices/InternExperience/Dashboard/Dashboard';
import { InternCandidatureApi } from '../Slices/InternExperience/InternCandidature/InternCandidature';
import { GetFeatureFlagApi } from '../Slices/Common/GetFeatureFlagSlice';
import { CompanyServices } from '../Slices/Common/CompanyServices';
import { CheckSlugApi } from '../Slices/InternExperience/RetrieveDetails/CheckSlug';
import { InterviewTimeSlotsApi } from '../Slices/InternExperience/Application/InterviewTimeSlots';
import { InternDetailsApi } from '../Slices/Company/ViTalentPool/InternDetails';
import { DeleteFileApi } from '../Slices/Common/DeleteS3FileRtk';
import { DashboardApi as DashboardApiCompany } from '../Slices/Company/Dashboard/dashboard';
import { ExpressInterestApi } from '../Slices/Common/ExpressInterest';
import { InternshipManagementApi } from '../Slices/InternExperience/InternshipManagement/InternshipManagement';
import { UpdateProfilePicApi } from '../Slices/Company/Profile/UpdateProfilePic';
import { SignInSignUpApi } from '../Slices/Common/signInSignUp';
import { OnboardingApi } from '../Slices/Company/onboarding';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['SetPastExpMode', 'SetApplicationFeedback', 'GetInternBatchDetails'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const reduxStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      // .concat(rootMiddleware)
      .concat(GetPersonalInformationApi.middleware)
      .concat(GetSkillsAndGoalsApi.middleware)
      .concat(GetProfilePictureApi.middleware)
      .concat(GetEducationApi.middleware)
      .concat(GetUniversityApi.middleware)
      .concat(GetPastExpApi.middleware)
      .concat(GetAdditionalQuestionApi.middleware)
      .concat(GetProfilePictureApi.middleware)
      .concat(GetApplicationShortApi.middleware)
      .concat(GetPortfolioApi.middleware)
      .concat(GetInternListApi.middleware)
      .concat(ShortListInternRemoveApi.middleware)
      .concat(ShortListInternSaveApi.middleware)
      .concat(GetShortListInternListApi.middleware)
      .concat(GetConfirmInterviewDetailApi.middleware)
      .concat(InternshipOpportunityApi.middleware)
      .concat(GetInternAvailableApi.middleware)
      .concat(LoadLocalesApi.middleware)
      .concat(GetCalendarListApi.middleware)
      .concat(CandidatureApi.middleware)
      .concat(CreditsApi.middleware)
      .concat(GetInterviewTimeApi.middleware)
      .concat(GetInternInterviewTimeApi.middleware)
      .concat(DashboardApi.middleware)
      .concat(InternCandidatureApi.middleware)
      .concat(GetFeatureFlagApi.middleware)
      .concat(GetFeatureFlagApi.middleware)
      .concat(CompanyServices.middleware)
      .concat(CheckSlugApi.middleware)
      .concat(InterviewTimeSlotsApi.middleware)
      .concat(InternDetailsApi.middleware)
      .concat(DeleteFileApi.middleware)
      .concat(DashboardApiCompany.middleware)
      .concat(ExpressInterestApi.middleware)
      .concat(InternshipManagementApi.middleware)
      .concat(UpdateProfilePicApi.middleware)
      .concat(SignInSignUpApi.middleware)
      .concat(OnboardingApi.middleware),
  devTools: configs.REACT_APP_ENV !== 'PROD',
});

export const waitForStoreUpdate = (dispatch) => {
  return new Promise((resolve) => {
    const unsubscribe = reduxStore.subscribe(() => {
      const state = reduxStore.getState();
      if (
        state.SetApplicationNextLoader.status === 'start' &&
        state.SetApplicationNextLoader.unsubscribe !== null
      ) {
        state.SetApplicationNextLoader.unsubscribe();
        resolve();
      }
    });
    dispatch(setUnsubscribe(unsubscribe));
  });
};
