import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const inviteNewColleaguesURL = '/host-company/admin-setting/invite';
const getAccountMembersURL = '/host-company/admin-setting/users';
const getAccountInterviewsURL = '/host-company/admin-setting/interviews';
const getAccountInterviewsCreditsURL = '/host-company/admin-setting/interview/credits';
const changeUserRoleURL = '/host-company/admin-setting/user/role';
const activateOrDeactivateUserURL = '/host-company/admin-setting/user/status';
const remindHostCompanyUserURL = '/host-company/admin-setting/remind/user';

const initialState = {
  inviteNewColleague: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  remindHostCompanyUser: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  getAccountMembers: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
    count: 0,
  },
  getAccountInterviews: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
    count: 0,
  },
  getAccountInterviewsCredits: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  changeUserRoleDetails: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  activateOrDeactivateUserDetails: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  getAdminUsersRequestParameters: {},
};

export const inviteNewColleagues = createAsyncThunk(
  'inviteNewColleagues',
  async (request, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
      const {
        AdminPageDetails: { getAdminUsersRequestParameters },
      } = getState();
      const result = await axios.post(inviteNewColleaguesURL, request);
      if (result?.data?.data?.isSuccess) {
        dispatch(getAccountMembersDetails(getAdminUsersRequestParameters));
        return fulfillWithValue(result?.data?.data);
      }
      if (!result?.data?.data?.isSuccess) {
        return rejectWithValue(result?.data?.data?.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const getAccountMembersDetails = createAsyncThunk(
  'getAccountMembersDetails',
  async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const result = await axios.post(getAccountMembersURL, request);
      if (result?.data?.data?.isSuccess) {
        dispatch(saveAdminUserRequest(request));
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const changeUserRole = createAsyncThunk(
  'changeUserRole',
  async (request, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
      const {
        AdminPageDetails: { getAdminUsersRequestParameters },
      } = getState();
      const result = await axios.put(changeUserRoleURL, request);
      if (result?.data?.data?.isSuccess) {
        dispatch(getAccountMembersDetails(getAdminUsersRequestParameters));
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const activateOrDeactivateUser = createAsyncThunk(
  'activateOrDeactivateUser',
  async (request, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
      const {
        AdminPageDetails: { getAdminUsersRequestParameters },
      } = getState();
      const result = await axios.put(activateOrDeactivateUserURL, request);
      if (result?.data?.data?.isSuccess) {
        dispatch(getAccountMembersDetails(getAdminUsersRequestParameters));
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const getAccountInterviewsDetails = createAsyncThunk(
  'getAccountInterviewsDetails',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.post(getAccountInterviewsURL, request);
      if (result?.data?.data?.isSuccess) {
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const getAccountInterviewsCreditsDetails = createAsyncThunk(
  'getAccountInterviewsCreditsDetails',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.get(getAccountInterviewsCreditsURL);
      if (result?.data?.status === 200) {
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const remindHostCompanyUserDetails = createAsyncThunk(
  'remindHostCompanyUserDetails',
  async (request, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
      const {
        AdminPageDetails: { getAdminUsersRequestParameters },
      } = getState();
      const result = await axios.post(remindHostCompanyUserURL, request);
      if (result?.data?.status === 200) {
        dispatch(getAccountMembersDetails(getAdminUsersRequestParameters));
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const adminPageDetailsSlice = createSlice({
  name: 'adminPageDetails',
  initialState,

  reducers: {
    saveAdminUserRequest: (state, action) => ({
      ...state,
      getAdminUsersRequestParameters: {
        ...action.payload,
      },
    }),
    inviteNewColleagueStateReset: (state) => ({
      ...state,
      inviteNewColleague: {
        status: 'idle',
        error: null,
        response: null,
        isLoading: false,
      },
    }),
    changeUserRoleDetailStateReset: (state) => ({
      ...state,
      changeUserRoleDetails: {
        status: 'idle',
        error: null,
        response: null,
        isLoading: false,
      },
    }),
    remindHostCompanyUserStateReset: (state) => ({
      ...state,
      remindHostCompanyUser: {
        status: 'idle',
        error: null,
        response: null,
        isLoading: false,
      },
    }),
    activateOrDeactivateUserStateReset: (state) => ({
      ...state,
      activateOrDeactivateUserDetails: {
        status: 'idle',
        error: null,
        response: null,
        isLoading: false,
      },
    }),
  },

  extraReducers(builder) {
    builder
      .addCase(inviteNewColleagues.pending, (state) => {
        state.inviteNewColleague = {
          ...state.inviteNewColleague,
          status: 'Pending',
          isLoading: true,
        };
      })
      .addCase(inviteNewColleagues.fulfilled, (state, action) => {
        state.inviteNewColleague = {
          ...state.inviteNewColleague,
          status: 'Success',
          response: action?.payload?.payload,
          isLoading: false,
        };
      })
      .addCase(inviteNewColleagues.rejected, (state, action) => {
        state.inviteNewColleague = {
          ...state.inviteNewColleague,
          status: 'Failed',
          error: action.error?.message || action.payload,
          isLoading: false,
        };
      })
      .addCase(getAccountMembersDetails.pending, (state) => {
        state.getAccountMembers = {
          ...state.getAccountMembers,
          status: 'Pending',
          isLoading: true,
        };
      })
      .addCase(getAccountMembersDetails.fulfilled, (state, action) => {
        state.getAccountMembers = {
          ...state.getAccountMembers,
          status: 'Success',
          response: action?.payload?.payload.map((x) => ({ info: x })),
          count: action?.payload?.count,
          isLoading: false,
        };
      })
      .addCase(getAccountMembersDetails.rejected, (state, action) => {
        state.getAccountMembers = {
          ...state.getAccountMembers,
          status: 'Failed',
          error: action.error?.message || action.payload,
          isLoading: false,
        };
      })
      .addCase(getAccountInterviewsDetails.pending, (state) => {
        state.getAccountInterviews = {
          ...state.getAccountInterviews,
          status: 'Pending',
          isLoading: true,
        };
      })
      .addCase(getAccountInterviewsDetails.fulfilled, (state, action) => {
        state.getAccountInterviews = {
          ...state.getAccountInterviews,
          status: 'Success',
          response: action?.payload?.payload.map((x) => ({ info: x })),
          count: action?.payload?.count,
          isLoading: false,
        };
      })
      .addCase(getAccountInterviewsDetails.rejected, (state, action) => {
        state.getAccountInterviews = {
          ...state.getAccountInterviews,
          status: 'Failed',
          error: action.error?.message || action.payload,
          isLoading: false,
        };
      })
      .addCase(getAccountInterviewsCreditsDetails.pending, (state) => {
        state.getAccountInterviewsCredits = {
          ...state.getAccountInterviewsCredits,
          status: 'Pending',
          isLoading: true,
        };
      })
      .addCase(getAccountInterviewsCreditsDetails.fulfilled, (state, action) => {
        state.getAccountInterviewsCredits = {
          ...state.getAccountInterviewsCredits,
          status: 'Success',
          response: action?.payload?.payload,
          isLoading: false,
        };
      })
      .addCase(getAccountInterviewsCreditsDetails.rejected, (state, action) => {
        state.getAccountInterviewsCredits = {
          ...state.getAccountInterviewsCredits,
          status: 'Failed',
          error: action.error?.message || action.payload,
          isLoading: false,
        };
      })
      .addCase(changeUserRole.pending, (state) => {
        state.changeUserRoleDetails = {
          ...state.changeUserRoleDetails,
          status: 'Pending',
          isLoading: true,
        };
      })
      .addCase(changeUserRole.fulfilled, (state, action) => {
        state.changeUserRoleDetails = {
          ...state.changeUserRoleDetails,
          status: 'Success',
          response: action?.payload?.payload,
          isLoading: false,
        };
      })
      .addCase(changeUserRole.rejected, (state, action) => {
        state.changeUserRoleDetails = {
          ...state.changeUserRoleDetails,
          status: 'Failed',
          error: action.error?.message || action.payload,
          isLoading: false,
        };
      })
      .addCase(activateOrDeactivateUser.pending, (state) => {
        state.activateOrDeactivateUserDetails = {
          ...state.activateOrDeactivateUserDetails,
          status: 'Pending',
          isLoading: true,
        };
      })
      .addCase(activateOrDeactivateUser.fulfilled, (state, action) => {
        state.activateOrDeactivateUserDetails = {
          ...state.activateOrDeactivateUserDetails,
          status: 'Success',
          response: action?.payload?.payload,
          isLoading: false,
        };
      })
      .addCase(activateOrDeactivateUser.rejected, (state, action) => {
        state.activateOrDeactivateUserDetails = {
          ...state.activateOrDeactivateUserDetails,
          status: 'Failed',
          error: action.error?.message || action.payload,
          isLoading: false,
        };
      })
      .addCase(remindHostCompanyUserDetails.pending, (state) => {
        state.remindHostCompanyUser = {
          ...state.remindHostCompanyUser,
          status: 'Pending',
          isLoading: true,
        };
      })
      .addCase(remindHostCompanyUserDetails.fulfilled, (state, action) => {
        state.remindHostCompanyUser = {
          ...state.remindHostCompanyUser,
          status: 'Success',
          response: action?.payload?.payload,
          isLoading: false,
        };
      })
      .addCase(remindHostCompanyUserDetails.rejected, (state, action) => {
        state.remindHostCompanyUser = {
          ...state.remindHostCompanyUser,
          status: 'Failed',
          error: action.error?.message || action.payload,
          isLoading: false,
        };
      });
  },
});

export const adminPageDetailsState = (state) => state.AdminPageDetails;
export default adminPageDetailsSlice.reducer;
export const {
  inviteNewColleagueStateReset,
  changeUserRoleDetailStateReset,
  activateOrDeactivateUserStateReset,
  remindHostCompanyUserStateReset,
  saveAdminUserRequest,
} = adminPageDetailsSlice.actions;
