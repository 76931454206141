/* eslint-disable no-useless-escape */
export const Regex = {
  name: /^[a-zA-Z]([.]?[a-zA-Z]?)*( [a-zA-Z]?([.]?[a-zA-Z]?)*)*$/,
  email: /^\w ([\.-]?\w )*@\w ([\.-]?\w )*(\.\w{2,3}) $/,
  companyName: /^([a-zA-Z0-9])([a-zA-Z0-9\s\.\,\/\&\-\(\)']?)*$/,
  year: /^\d{4}$/,
  // eslint-disable-next-line no-irregular-whitespace
  url: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\ \$,\w] @)?[A-Za-z0-9.‌​-] (:[0-9] )?|(?:www‌​.|[-;:&=\ \$,\w] @)[‌​A-Za-z0-9.-] )((?:\/‌​[\ ~%\/.\w-_]*)?\??(‌​?:[-\ =&;%@.\w_]*)#?‌​(?:[\w]*))?)/,
  alphaNumericWithSpace: /^[a-zA-Z0-9 ]*$/,
  linkedinCompany: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/company\/([-a-zA-Z0-9-]+)\/*/m,
  linkedinUser: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/in\/([-a-zA-Z0-9-]+)\/*/m,
  linkedin: /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/m,
  facebook:
    /^(http\:\/\/|https\:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/,
  twitter: /(?:https?:)?\/\/(?:www\.|m\.)?twitter\.com\/(\w{2,15})\/?(?:\?\S+)?(?:\#\S+)?$/m,
  youtube:
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/m,
  tiktok:
    /^(?:http(?:s)?:\/\/)?(?:(?:www)\.(?:tiktok\.com)(?:\/)(?!foryou)(@[a-zA-z0-9]+)(?:\/)(?:video)(?:\/)([\d]+)|(?:m)\.(?:tiktok\.com)(?:\/)(?!foryou)(?:v)(?:\/)?(?=([\d]+)\.html))/m,
  instagram: /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/m,
  portfolioUrl:
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
  valid_email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  valid_url: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/,
  valid_url_with_https: /^https?:\/\/([\w.-]+)\.([a-z]{2,})(:\d{2,5})?([/?#]\S*)?$/i,
};
export const SPECIAL_CHAR_REGEX = /[^\w\d\s-]/g;
export const SPECIAL_CHAR_REGEX_WITHOUT_PERCENT = /[^%\w\d\s-]/g;
export const ATLEAST_ONE_ALPHABET_REGEX = /.*[a-zA-Z] .*/;
export const MULTIPLE_SPACE_REGEX = /\s /g;
