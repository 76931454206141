import * as countryCodes from 'country-codes-list';

export const getAllCountryPhoneCode = countryCodes.customList(
  'countryNameEn',
  '+{countryCallingCode}'
);

export const getCountryByName = (Name) => countryCodes.findOne('countryNameEn', Name);

export const getCountryByCode = (code) => countryCodes.findOne('countryCode', code);
