import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios/Axios';

const saveInvitedUserDetailsURL = '/host-company/invited/user';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const saveInvitedUserDetails = createAsyncThunk(
  'saveInvitedUserDetails',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.put(saveInvitedUserDetailsURL, request);
      if (result?.data?.data?.isSuccess) {
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue(result?.data?.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const saveInvitedUserDetailsSlice = createSlice({
  name: 'saveInvitedUserDetails',
  initialState,

  reducers: {
    clearSaveInvitedUserDetails: () => ({
      status: 'idle',
      error: null,
      response: null,
      isLoading: false,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(saveInvitedUserDetails.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(saveInvitedUserDetails.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
        state.isLoading = false;
      })
      .addCase(saveInvitedUserDetails.rejected, (state, action) => {
        state.status = 'Failed';
        state.error = action?.payload;
        state.isLoading = false;
      });
  },
});

export const saveInvitedUserDetailsState = (state) => state.SaveInvitedUserDetails;
export default saveInvitedUserDetailsSlice.reducer;
export const { clearSaveInvitedUserDetails } = saveInvitedUserDetailsSlice.actions;
